
import Image1 from './assets/images/1.jpg'
import Image2 from './assets/images/2.jpg'
import Image3 from './assets/images/3.jpg'
import Image4 from './assets/images/4.jpg'
import Image5 from './assets/images/5.jpg'
import Image6 from './assets/images/6.jpg'
import Image7 from './assets/images/7.jpg'
import Image8 from './assets/images/8.jpg'
import Image9 from './assets/images/9.jpg'
export  const cars = [
    {id:1,name:'ferary',description:'The fastes car in this word i can drive it to 240 k/h',image:Image1,price:1000,rate:4.2,like:10},
    {id:2,name:'Honday',description:'The fastes car in this word i can drive it to 240 k/h The fastes car in this word i can drive it to 240 k/h',image:Image2,price:100,rate:3.2,like:330},
    {id:3,name:'BMW',description:'The fastes car in this word i can drive it to 240 k/h',image:Image3,price:130,rate:4.3,like:500},
    {id:4,name:'Audi',description:'The fastes car in this word i can drive it to 240 k/h',image:Image4,price:140,rate:5,like:30},
    
    {id:5,name:'ferary',description:'The fastes car in this word i can drive it to 240 k/h',image:Image5,price:1000,rate:4.2,like:10},
    {id:6,name:'Honday',description:'The fastes car in this word i can drive it to 240 k/h The fastes car in this word i can drive it to 240 k/h',image:Image6,price:100,rate:3.2,like:330},
    {id:7,name:'BMW',description:'The fastes car in this word i can drive it to 240 k/h',image:Image7,price:130,rate:4.3,like:500},
    {id:8,name:'Audi',description:'The fastes car in this word i can drive it to 240 k/h',image:Image8,price:140,rate:5,like:30},
    {id:9,name:'Audi',description:'The fastes car in this word i can drive it to 240 k/h',image:Image9,price:140,rate:3.3,like:50},
];

export let SlideData = [
    {id:1,name:'Honday',rate:4.3,like:500,image:Image5,description:'this the short description'},
    {id:2,name:'Ferary',rate:4.3,like:500,image:Image6,description:'this the short description'},
    {id:3,name:'BMW',rate:4.3,like:500,image:Image7,description:'this the short description'},
    {id:4,name:'Audi',rate:4.3,like:500,image:Image8,description:'this the short description'},

]